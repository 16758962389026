@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Bahij TheSansArabic Light";
  src: local("Bahij TheSansArabic Light"),
    url(./fonts/TheSansArabic-Light.ttf) format("truetype");
}

@layer utilities {
  @variants responsive {
    .rtl {
      direction: rtl;
    }
    .h-80 {
      height: 80vh;
    }

    .h-100 {
      height: 100vh;
    }

    .h-125 {
      height: 125vh;
    }

    .h-150 {
      height: 150vh;
    }

    .h-new-48 {
      height: 48vh;
    }

    .max-h-120 {
      max-height: 30rem /* 384px */;
    }

    .w-120 {
      width: 30rem;
    }

    .w-140 {
      width: 36rem;
    }

    .w-160 {
      width: 42rem;
    }
    .width-600 {
      width: 600px;
    }

    .text-gradient {
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .h-about-pics {
      height: 450px;
    }

    .w-about-pics {
      width: 450px;
    }

    .h-about-pics-xl {
      height: 650px;
    }

    .w-about-pics-xl {
      width: 650px;
    }

    /* .bg-reghda-light-blue {
      background-color: #dde9ee;
    }

    .bg-reghda-blue {
      background-color: #91b6c6;
    }

    .text-reghda-light-blue {
      color: #dde9ee;
    }

    .text-reghda-blue {
      color: #91b6c6;
    }

    .border-reghda-blue {
      border-color: #91b6c6;
    } */

    .bg-reghda-light-blue {
      background-color: #fbf3f1;
    }

    .bg-reghda-blue {
      background-color: #efb7b6;
    }

    .text-reghda-light-blue {
      color: #fbf3f1;
    }

    .text-reghda-blue {
      color: #efb7b6;
    }

    .border-reghda-blue {
      border-color: #efb7b6;
    }

    .bg-reghda-black {
      background-color: #5b5b5b;
    }

    .text-reghda-black {
      color: #5b5b5b;
    }

    .border-reghda-black {
      border-color: #5b5b5b;
    }

    .border-1 {
      border-width: 1px;
    }
    .border-bottom-1 {
      border-bottom: 1px solid;
    }

    .text-xxs {
      font-size: 0.5rem;
      line-height: 0.75rem;
    }
    .left-45 {
      left: 45%;
    }

    .width-60 {
      width: 60%;
    }
  }
}
