.react-calendar {
  width: 100%;
  border: none;
  padding: 1rem;
}
.react-calendar__month-view__days {
  text-align: center;
}

.react-calendar__viewContainer {
  text-align: center;
}

.react-calendar__tile:disabled {
  color: rgb(153, 152, 152);
}
.react-calendar__tile--active {
  background: #5b5b5b;
  color: #e7bdcb;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #5b5b5b;
  color: #e7bdcb;
}

.react-calendar__tile--active:hover,
.react-calendar__tile--active:focus {
  background: #5b5b5b;
  color: #e7bdcb;
}

.react-calendar button {
  font-family: "Bahij TheSansArabic Light";
}
.react-calendar__tile {
  text-align: center;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__tile--now {
  background: white;
  color: black;
}

.react-calendar__tile--now:hover {
  background: white;
  color: black;
}

.react-calendar__month-view__weekdays__weekday {
  font-family: "Bahij TheSansArabic Light";
}

@media screen and (max-width: 768px) {
  .react-calendar__tile {
    text-align: center;
    padding: 1%;
  }
}

@media screen and (max-width: 610px) {
  .react-calendar__navigation {
    flex-direction: column;
    margin-bottom: 6rem;
  }
  .react-calendar__month-view__days {
    display: flex;
    justify-content: center;
  }
  .react-calendar__navigation__label {
    padding: 1rem 0;
  }
}

.schedule span {
  margin: 0 0.1rem;
}
